import clsx from 'clsx'
import React from 'react'
import { UserIcon } from '@heroicons/react/24/solid'

type AvatarProps = {
  src?: string | null
  alt?: string
  className?: string
  size?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '4xl'
  loading?: boolean
}

const sizeClasses = {
  sm: 'w-[32px] h-[32px]',
  md: 'w-[40px] h-[40px]',
  lg: 'w-[48px] h-[48px]',
  xl: 'w-[64px] h-[64px]',
  '2xl': 'w-[96px] h-[96px]',
  '4xl': 'w-[128px] h-[128px]',
}

const iconSizeClasses = {
  sm: 'h-5 w-5',
  md: 'h-6 w-6',
  lg: 'h-7 w-7',
  xl: 'h-10 w-10',
  '2xl': 'h-14 w-14',
  '4xl': 'h-20 w-20',
}

export function Avatar({
  src = null,
  alt = '',
  className,
  size = 'md',
  loading = false,
  ...props
}: AvatarProps & React.ComponentPropsWithoutRef<'span'>) {
  return (
    <span
      data-slot="avatar"
      className={clsx(
        'flex items-center justify-center rounded-full bg-gray-100',
        sizeClasses[size],
        className
      )}
      {...props}
    >
      {loading ? (
        <div className="animate-pulse bg-gray-200 h-full w-full rounded-full" />
      ) : src ? (
        <img
          src={src}
          alt={alt}
          className="h-full w-full rounded-full object-cover"
        />
      ) : (
        <UserIcon
          className={clsx('text-gray-500', iconSizeClasses[size])}
          aria-hidden={!alt}
        />
      )}
    </span>
  )
}
