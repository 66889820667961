import { Form, SubmitHandler } from '@redwoodjs/forms'
import { useMutation } from '@apollo/client'
import { toast } from '@redwoodjs/web/dist/toast'
import { useAuth } from 'src/auth'
import { navigate } from '@redwoodjs/router'
import {
  Field,
  FieldGroup,
  Fieldset,
  Legend,
} from 'src/components/catalyst/fieldset'
import Input from 'src/components/catalyst/rw/Input'
import { Button } from 'src/components/catalyst/button'
import { UPDATE_USER_MUTATION } from 'src/queries/users'
import { useForm } from '@redwoodjs/forms'

export const OnboardingProfile = () => {
  const { currentUser } = useAuth()
  const [updateUser] = useMutation(UPDATE_USER_MUTATION)
  const queryParams = new URLSearchParams(window.location.search)
  const invitationToken = queryParams.get('invitation_token')

  const formMethods = useForm({
    defaultValues: {
      fullName: currentUser?.fullName || '',
      title: currentUser?.title || '',
    },
  })

  type FormFields = {
    fullName: string
    title: string
  }

  const onSubmit: SubmitHandler<FormFields> = async (data) => {
    try {
      await updateUser({
        variables: {
          input: {
            id: currentUser.id,
            fullName: data.fullName,
            title: data.title,
            email: currentUser.email,
          },
        },
      })

      if (invitationToken) {
        navigate('/onboardingDisclaimer?invitation_token=' + invitationToken)
      } else {
        if (currentUser.roleId === 3 || currentUser.roleId === 4) {
          navigate('/onboardingDisclaimer')
        } else {
          navigate('/onboardingOrganization')
        }
      }
    } catch (error) {
      toast.error(error.message)
    }
  }
  return (
    <div className="w-full flex flex-col items-center justify-center h-[100vh]">
      <div className="w-lg-form-width flex font-roboto flex-col border-2 px-16 pt-4 pb-12 items-center border-border rounded-xl">
        <span className=" text-black dark:text-white">
          <img
            src="/silo-logo.svg"
            alt="silo logo"
            className="w-md-image text-black dark:text-white"
          />
        </span>
        <Form
          action="/orders"
          method="POST"
          className=" flex-col gap-4 w-full"
          onSubmit={onSubmit}
          formMethods={formMethods}
        >
          <Fieldset className="flex flex-col ">
            <Legend className=" text-xl text-center">User Details</Legend>

            <FieldGroup className="flex flex-col gap-2">
              <Field>
                <Input
                  name="fullName"
                  label="Full Name"
                  className="border-1 border-border"
                />
              </Field>
              <Field>
                <Input
                  name="title"
                  label="Job Title"
                  className="border-1 border-border"
                />
              </Field>
            </FieldGroup>
          </Fieldset>
          <Button color="primary" className="w-full mt-8 " type="submit">
            Save
          </Button>
        </Form>
      </div>
    </div>
  )
}
