import {
  Checkbox as HeadlessCheckbox,
  Field as HeadlessField,
  type CheckboxProps as HeadlessCheckboxProps,
  type FieldProps as HeadlessFieldProps,
} from '@headlessui/react'
import { clsx } from 'clsx'
import type React from 'react'

export function CheckboxGroup({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      data-slot="control"
      {...props}
      className={clsx(
        className,

        // Basic groups
        'space-y-3',

        // With descriptions
        'has-[[data-slot=description]]:space-y-6 [&_[data-slot=label]]:has-[[data-slot=description]]:font-medium'
      )}
    />
  )
}

export function CheckboxField({ className, ...props }: HeadlessFieldProps) {
  return (
    <HeadlessField
      data-slot="field"
      {...props}
      className={clsx(
        className,

        // Base layout
        'grid grid-cols-[1.125rem_1fr] items-center gap-x-4 gap-y-1 sm:grid-cols-[1rem_1fr]',

        // Control layout
        '[&>[data-slot=control]]:col-start-1 [&>[data-slot=control]]:row-start-1 [&>[data-slot=control]]:justify-self-center',

        // Label layout
        '[&>[data-slot=label]]:col-start-2 [&>[data-slot=label]]:row-start-1 [&>[data-slot=label]]:justify-self-start',

        // Description layout
        '[&>[data-slot=description]]:col-start-2 [&>[data-slot=description]]:row-start-2',

        // With description
        '[&_[data-slot=label]]:has-[[data-slot=description]]:font-medium'
      )}
    />
  )
}

const base = [
  // Basic layout
  'group relative isolate flex size-[1.1875rem] shrink-0 items-center justify-center rounded-[0.3125rem] sm:size-[1.0625rem]',

  // Background color + shadow applied to inset pseudo element, so shadow blends with border in light mode
  'before:absolute before:inset-0 before:-z-10 before:rounded-[calc(0.3125rem-1px)] before:bg-white before:shadow',

  // Background color when checked
  'before:group-data-[checked]:bg-[--checkbox-checked-bg]',
  'before:group-data-[checked]:shadow-none',

  // Background color is moved to control and shadow is removed in dark mode
  'dark:before:hidden',

  // Background color applied to control in dark mode
  'dark:bg-white/5 dark:group-data-[checked]:bg-[--checkbox-checked-bg]',

  // Border
  'border border-zinc-950/15 group-data-[checked]:border-[--checkbox-checked-border] group-data-[checked]:group-data-[hover]:border-[--checkbox-checked-border] group-data-[hover]:border-zinc-950/30',
  'dark:border-white/15 dark:group-data-[checked]:border-transparent dark:group-data-[checked]:group-data-[hover]:border-transparent dark:group-data-[hover]:border-white/30',

  // Inner highlight shadow - removed for checked state
  'after:absolute after:inset-0 after:rounded-[calc(0.3125rem-1px)]',
  'group-data-[checked]:after:hidden',
  'dark:after:hidden',

  // Focus ring
  'group-data-[focus]:outline group-data-[focus]:outline-2 group-data-[focus]:outline-offset-2 group-data-[focus]:outline-blue-500',

  // Disabled state
  'group-data-[disabled]:opacity-50',
  'dark:group-data-[disabled]:border-white/20 dark:group-data-[disabled]:bg-white/[2.5%] dark:group-data-[disabled]:[--checkbox-check:theme(colors.white/50%)]',
]

const colors = {
  primary: [
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.primary)] [--checkbox-checked-border:theme(colors.primary)]',
    'dark:[--checkbox-checked-bg:theme(colors.zinc.600)] dark:group-data-[checked]:border-transparent',
  ],
  secondary: [
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.secondary)] [--checkbox-checked-border:theme(colors.secondary)]',
    'dark:[--checkbox-checked-bg:theme(colors.zinc.600)] dark:group-data-[checked]:border-transparent',
  ],
  accent: [
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.accent)] [--checkbox-checked-border:theme(colors.accent)]',
    'dark:[--checkbox-checked-bg:theme(colors.zinc.600)] dark:group-data-[checked]:border-transparent',
  ],
  muted: [
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.muted)] [--checkbox-checked-border:theme(colors.muted/90%)]',
    'dark:[--checkbox-checked-bg:theme(colors.zinc.600)]',
  ],
  light: [
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.light)] [--checkbox-checked-border:theme(colors.light/90%)]',
    'dark:[--checkbox-checked-bg:theme(colors.zinc.600)]',
  ],
  'dark/zinc': [
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.zinc.900)] [--checkbox-checked-border:theme(colors.zinc.950/90%)]',
    'dark:[--checkbox-checked-bg:theme(colors.zinc.600)]',
  ],
  'dark/white': [
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.zinc.900)] [--checkbox-checked-border:theme(colors.zinc.950/90%)]',
    'dark:[--checkbox-check:theme(colors.zinc.900)] dark:[--checkbox-checked-bg:theme(colors.white)] dark:[--checkbox-checked-border:theme(colors.zinc.950/15%)]',
  ],
  white:
    '[--checkbox-check:theme(colors.zinc.900)] [--checkbox-checked-bg:theme(colors.white)] [--checkbox-checked-border:theme(colors.zinc.950/15%)]',
  dark: '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.zinc.900)] [--checkbox-checked-border:theme(colors.zinc.950/90%)]',
  zinc: '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.zinc.600)] [--checkbox-checked-border:theme(colors.zinc.700/90%)]',
  red: '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.red.600)] [--checkbox-checked-border:theme(colors.red.700/90%)]',
  orange:
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.orange.500)] [--checkbox-checked-border:theme(colors.orange.600/90%)]',
  amber:
    '[--checkbox-check:theme(colors.amber.950)] [--checkbox-checked-bg:theme(colors.amber.400)] [--checkbox-checked-border:theme(colors.amber.500/80%)]',
  yellow:
    '[--checkbox-check:theme(colors.yellow.950)] [--checkbox-checked-bg:theme(colors.yellow.300)] [--checkbox-checked-border:theme(colors.yellow.400/80%)]',
  lime: '[--checkbox-check:theme(colors.lime.950)] [--checkbox-checked-bg:theme(colors.lime.300)] [--checkbox-checked-border:theme(colors.lime.400/80%)]',
  green:
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.green.600)] [--checkbox-checked-border:theme(colors.green.700/90%)]',
  emerald:
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.emerald.600)] [--checkbox-checked-border:theme(colors.emerald.700/90%)]',
  teal: '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.teal.600)] [--checkbox-checked-border:theme(colors.teal.700/90%)]',
  cyan: '[--checkbox-check:theme(colors.cyan.950)] [--checkbox-checked-bg:theme(colors.cyan.300)] [--checkbox-checked-border:theme(colors.cyan.400/80%)]',
  sky: '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.sky.500)] [--checkbox-checked-border:theme(colors.sky.600/80%)]',
  blue: [
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.blue.600)] [--checkbox-checked-border:theme(colors.blue.600)]',
    'dark:[--checkbox-checked-bg:theme(colors.blue.600)]',
  ],
  indigo:
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.indigo.500)] [--checkbox-checked-border:theme(colors.indigo.600/90%)]',
  violet:
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.violet.500)] [--checkbox-checked-border:theme(colors.violet.600/90%)]',
  purple:
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.purple.500)] [--checkbox-checked-border:theme(colors.purple.600/90%)]',
  fuchsia:
    '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.fuchsia.500)] [--checkbox-checked-border:theme(colors.fuchsia.600/90%)]',
  pink: '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.pink.500)] [--checkbox-checked-border:theme(colors.pink.600/90%)]',
  rose: '[--checkbox-check:theme(colors.white)] [--checkbox-checked-bg:theme(colors.rose.500)] [--checkbox-checked-border:theme(colors.rose.600/90%)]',
}

type Color = keyof typeof colors

export function Checkbox({
  color = 'blue',
  className,
  noBorder = false,
  ...props
}: {
  color?: Color
  className?: string
  noBorder?: boolean
} & HeadlessCheckboxProps) {
  const baseStyles = noBorder
    ? base.filter((style) => !style.includes('border'))
    : base

  return (
    <HeadlessCheckbox
      data-slot="control"
      className={clsx(
        className,
        'group inline-flex focus:outline-none cursor-pointer'
      )}
      {...props}
    >
      <span className={clsx([baseStyles, colors[color]])}>
        <svg
          className="size-2 stroke-[--checkbox-check] opacity-0 group-data-[checked]:opacity-100 sm:h-2.5 sm:w-2.5"
          viewBox="0 0 14 14"
          fill="none"
        >
          {/* Checkmark icon */}
          <path
            className="opacity-100 group-data-[indeterminate]:opacity-0"
            d="M3 8L6 11L11 3.5"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          {/* Indeterminate icon */}
          <path
            className="opacity-0 group-data-[indeterminate]:opacity-100"
            d="M3 7H11"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </HeadlessCheckbox>
  )
}
