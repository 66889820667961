'use client'

import {
  Description as HeadlessDescription,
  Label as HeadlessLabel,
  Menu as HeadlessMenu,
  MenuButton as HeadlessMenuButton,
  MenuHeading as HeadlessMenuHeading,
  MenuItem as HeadlessMenuItem,
  MenuItems as HeadlessMenuItems,
  MenuSection as HeadlessMenuSection,
  MenuSeparator as HeadlessMenuSeparator,
  Transition as HeadlessTransition,
  type DescriptionProps as HeadlessDescriptionProps,
  type LabelProps as HeadlessLabelProps,
  type MenuHeadingProps as HeadlessMenuHeadingProps,
  type MenuItemProps as HeadlessMenuItemProps,
  type MenuItemsProps as HeadlessMenuItemsProps,
  type MenuProps as HeadlessMenuProps,
  type MenuSectionProps as HeadlessMenuSectionProps,
  type MenuSeparatorProps as HeadlessMenuSeparatorProps,
} from '@headlessui/react'
import clsx from 'clsx'
import type React from 'react'
//import { Button } from './button'
import { Link } from './link'

export function Dropdown(props: HeadlessMenuProps) {
  return <HeadlessMenu {...props} />
}

export function DropdownButton(
  props: React.ComponentPropsWithoutRef<typeof HeadlessMenuButton>
) {
  return (
    <HeadlessMenuButton
      as={props.as || 'button'}
      {...props}
      className={clsx(
        'inline-flex items-center justify-center focus:outline-none',
        'bg-transparent hover:bg-transparent active:bg-transparent',
        'border-0 shadow-none',
        props.className
      )}
    />
  )
}

export function DropdownMenu({
  anchor = 'bottom',
  ...props
}: HeadlessMenuItemsProps) {
  return (
    <HeadlessTransition leave="duration-100 ease-in" leaveTo="opacity-0">
      <HeadlessMenuItems
        {...props}
        anchor={anchor}
        className={clsx(
          props.className,

          // Anchor positioning
          '[--anchor-gap:theme(spacing.2)] [--anchor-padding:theme(spacing.3)] data-[anchor~=end]:[--anchor-offset:4px] data-[anchor~=start]:[--anchor-offset:-4px]',

          // Base styles
          'isolate w-max rounded-xl p-1',

          // Invisible border that is only visible in `forced-colors` mode for accessibility purposes
          'outline outline-1 outline-transparent focus:outline-none',

          // Handle scrolling when menu won't fit in viewport
          'overflow-y-auto',

          // Popover background
          'bg-white/75 backdrop-blur-xl dark:bg-zinc-800/75',

          // Shadows
          // 'shadow-lg ring-1 ring-zinc-950/10 dark:ring-inset dark:ring-white/10',

          // Define grid at the menu level if subgrid is supported
          'supports-[grid-template-columns:subgrid]:grid supports-[grid-template-columns:subgrid]:grid-cols-[auto_1fr_1.5rem_0.5rem_auto]'
        )}
      />
    </HeadlessTransition>
  )
}

export function DropdownItem(
  props: { href?: string } & HeadlessMenuItemProps<'button'>
) {
  return (
    <HeadlessMenuItem
      as={props.href ? Link : 'button'}
      type={props.href ? undefined : 'button'}
      {...props}
      className={clsx(
        props.className,
        // Base styles
        'group cursor-pointer rounded-lg px-3.5 py-2.5 focus:outline-none sm:px-3 sm:py-1.5',

        // Text styles
        'text-left text-base/6 text-zinc-950 sm:text-sm/6 dark:text-white forced-colors:text-[CanvasText]',

        // Focus
        'data-[focus]:bg-light data-[focus]:text-black',

        // Disabled state
        'data-[disabled]:opacity-50',

        // Forced colors mode
        'forced-color-adjust-none forced-colors:data-[focus]:bg-[Highlight] forced-colors:data-[focus]:text-[HighlightText] forced-colors:[&>[data-slot=icon]]:data-[focus]:text-[HighlightText]',

        // Grid layout with tight icon spacing but maintaining width
        'col-span-full grid grid-cols-[1.25rem_1fr_1.5rem_0.5rem_auto] items-center',

        // Icon
        '[&>[data-slot=icon]]:size-5 sm:[&>[data-slot=icon]]:size-4',
        '[&>[data-slot=icon]]:text-zinc-950 [&>[data-slot=icon]]:data-[focus]:text-zinc-950'
      )}
    />
  )
}

export function DropdownHeader({
  className,
  ...props
}: React.ComponentPropsWithoutRef<'div'>) {
  return (
    <div
      {...props}
      className={clsx(className, 'col-span-5 px-3.5 pb-1 pt-2.5 sm:px-3')}
    />
  )
}

export function DropdownSection({
  className,
  ...props
}: HeadlessMenuSectionProps) {
  return (
    <HeadlessMenuSection
      {...props}
      className={clsx(
        className,
        // Define grid at the section level instead of the item level if subgrid is supported
        'col-span-full supports-[grid-template-columns:subgrid]:grid supports-[grid-template-columns:subgrid]:grid-cols-[auto_1fr_1.5rem_0.5rem_auto]'
      )}
    />
  )
}

export function DropdownHeading({
  className,
  ...props
}: HeadlessMenuHeadingProps) {
  return (
    <HeadlessMenuHeading
      {...props}
      className={clsx(
        className,
        'col-span-full grid grid-cols-[1fr,auto] gap-x-12 px-3.5 pb-1 pt-2 text-sm/5 font-medium text-zinc-500 sm:px-3 sm:text-xs/5 dark:text-zinc-400'
      )}
    />
  )
}

export function DropdownSeparator({
  className,
  ...props
}: HeadlessMenuSeparatorProps) {
  return (
    <HeadlessMenuSeparator
      {...props}
      className={clsx(
        className,
        'col-span-full mx-3.5 my-1 h-px border-0 bg-zinc-950/5 sm:mx-3 dark:bg-white/10 forced-colors:bg-[CanvasText]'
      )}
    />
  )
}

export function DropdownLabel({ className, ...props }: HeadlessLabelProps) {
  return (
    <HeadlessLabel
      {...props}
      data-slot="label"
      className={clsx(className, 'col-start-2 row-start-1')}
      {...props}
    />
  )
}

export function DropdownDescription({
  className,
  ...props
}: HeadlessDescriptionProps) {
  return (
    <HeadlessDescription
      data-slot="description"
      {...props}
      className={clsx(
        className,
        'col-span-2 col-start-2 row-start-2 text-sm/5 text-zinc-500 group-data-[focus]:text-white sm:text-xs/5 dark:text-zinc-400 forced-colors:group-data-[focus]:text-[HighlightText]'
      )}
    />
  )
}

export function DropdownShortcut({
  className,
  keys,
  ...props
}: { keys: string | string[] } & HeadlessDescriptionProps<'kbd'>) {
  return (
    <HeadlessDescription
      as="kbd"
      {...props}
      className={clsx(
        className,
        'col-start-5 row-start-1 flex justify-self-end'
      )}
    >
      {(Array.isArray(keys) ? keys : keys.split('')).map((char, index) => (
        <kbd
          key={index}
          className={clsx([
            'min-w-[2ch] text-center font-sans capitalize text-zinc-400 group-data-[focus]:text-white forced-colors:group-data-[focus]:text-[HighlightText]',

            // Make sure key names that are longer than one character (like "Tab") have extra space
            index > 0 && char.length > 1 && 'pl-1',
          ])}
        >
          {char}
        </kbd>
      ))}
    </HeadlessDescription>
  )
}
