export const AUTH0_AUDIENCE = process.env.AUTH0_AUDIENCE
export const AUTH0_CLIENT_ID = process.env.AUTH0_CLIENT_ID
export const AUTH0_DOMAIN = process.env.AUTH0_DOMAIN
export const AUTH0_REDIRECT_URI = process.env.AUTH0_REDIRECT_URI
export const GEO_API_URI = process.env.GEO_API_URI
export const GEOSERVICE_URI = `${GEO_API_URI}/geoservice`
export const TILESERV_URI = `${GEO_API_URI}/tileserv`
export const MAPBOX_TOKEN = process.env.MAPBOX_TOKEN
export const GEOMETRY_BUCKET_NAME = process.env.AWS_GEOMETRY_BUCKET_NAME
export const PROFILE_BUCKET_NAME = process.env.AWS_PROFILE_BUCKET_NAME

// REDWOOD_BUG: 4/26/24
// Ideally we use this format, but it doesn't work with the current Redwood frontend
// build process. Will file a bug with Redwood and perhaps we'll be able to use this
// format in the future.
// export const {
//   AUTH0_AUDIENCE,
//   AUTH0_CLIENT_ID,
//   AUTH0_DOMAIN,
//   AUTH0_REDIRECT_URI,
//   GEO_API_URI,
//   MAPBOX_TOKEN,
// } = process.env
