import { Text } from '../catalyst/text'

export default function Scope() {
  return (
    <div
      id="scope"
      className="flex justify-center items-center bg-none h-full w-full"
    >
      <div className="mx-auto max-w-[1000px] lg:mx-0 z-10 p-4 rounded-lg ">
        <div className="flex flex-col gap-2">
          <Text>
            SILO is a desktop and mobile mapping application which functions as
            a multi-user database linking public GIS datasets to your drafting
            software, whether it&apos;s AutoDesk AutoCad LT, Civil 3D or similar
            drafting software. SILO gives you the opportunity to have one
            location to source public GIS data and a catalogue of all pertinent
            project data. The GIS data we focus on procuring are elevation
            contours, PLSS quarter sections, building footprints, easements and
            roads, the entire FEMA Flood Hazard Layer, parcel lines, utilities,
            and environmentally critical areas - rivers, streams, waterbodies,
            wetlands, erosion/landslide hazards, steep slopes, soils, all of
            which are sourced from federal and local data hubs. For most
            datasets, our engineers have created permanent links, so we are
            essentially &apos;streaming&apos; current data. If the data is not
            being &apos;streamed&apos;, we supply the date of the last harvest
            from the source. If a URL link exists for the source data, it is
            accessible by clicking the icon to the right of the layer. We
            understand how important it is to cite sources of non-field measured
            data, so we are fully transparent, enabling you to cite the data you
            use.
          </Text>

          <Text>
            As stated, SILO can also house your own information, so you can
            create points, lines, and polygons; additionally, if there are
            public datasets to which we are not linked, we currently support
            .shp files for upload to the app, (soon, we will support .kml and
            .csv!) to be viewed and accessed privately by you and your
            organization. Extracting the data from the app is as easy as
            clicking a button. A .shp or .dxf download will be produced of all
            the active layers on your screen. Exporting to .csv is in the works
            and will enable you to export field located data along with all
            pertinent attributes. To help, we provide our preferred workflows
            for importing and exporting data to or from your preferred drafting
            software, whether it&apos;s AutoDesk AutoCad LT or Civil 3D. We
            offer support for additional software packages and build out those
            workflows on an as needed basis.
          </Text>
          <Text className="mt-2 font-semibold">
            Reach out to us anytime at{' '}
            <a
              href="mailto:info@silogeo.com"
              className="text-blue-600 hover:text-blue-800 transition-colors"
            >
              info@silogeo.com
            </a>
            .
          </Text>
        </div>
      </div>
    </div>
  )
}
