import {
  Field,
  ErrorMessage,
  Label,
  Description,
} from 'src/components/catalyst/fieldset'
import { Select as CatalystSelect } from 'src/components/catalyst/select'
import {
  type FieldProps,
  type LabelProps,
  type SelectProps,
} from '@headlessui/react'
import { Controller, RegisterOptions, useErrorStyles } from '@redwoodjs/forms'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

const PLACEHOLDER = '__PLACEHOLDER__'
interface Option {
  id: number
  name: string
  value: string
}

interface Props {
  name: string
  label?: string
  defaultValue?: string | number
  placeholderText?: string
  description?: string
  options?: Option[]
  className?: string
  style?: React.StyleHTMLAttributes<HTMLSelectElement>
  errorClassName?: string
  fieldErrorClassName?: string
  labelProps?: { className?: string } & LabelProps
  fieldProps?: FieldProps
  selectProps?: SelectProps
  validation?: RegisterOptions
  loading?: boolean
}

const Select = (props: Props) => {
  const {
    name,
    label = `${props.name.charAt(0).toUpperCase()}${props.name.slice(1)}`,
    defaultValue = PLACEHOLDER,
    placeholderText = 'Select an option',
    description,
    options,
    className = 'select',
    errorClassName = 'error border-red-500',
    labelProps = {
      className: 'block text-lg font-light text-gray-600 mb-2',
    },
    fieldProps,
    selectProps,
    validation = {},
    loading,
  } = props

  const { className: componentClassName } = useErrorStyles({
    className: className,
    errorClassName: errorClassName,
    name: name,
  })

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      rules={{
        validate: {
          matchesInitialValue: (value) => {
            if (!validation.required) {
              return true
            } else {
              const message =
                typeof validation.required === 'string'
                  ? validation.required
                  : placeholderText
              return value !== PLACEHOLDER || message
            }
          },
        },
        ...validation,
      }}
      render={({
        field: { onChange, value, name, ref },
        formState: { errors },
      }) => (
        <>
          <Field {...fieldProps}>
            <Label {...labelProps}>{label}</Label>
            {description && <Description>{description}</Description>}
            <div className="relative w-full">
              <CatalystSelect
                name={name}
                value={value}
                onChange={onChange}
                className={`${componentClassName} bg-white text-[#61758D] w-full rounded-md py-2 pl-3 pr-8 focus:outline-none cursor-pointer ${
                  value === PLACEHOLDER ? 'text-[#61758D]' : 'text-[#61758D]'
                }`}
                ref={ref}
                style={{
                  WebkitAppearance: 'none',
                  MozAppearance: 'none',
                  appearance: 'none',
                }}
                {...selectProps}
              >
                <option value={PLACEHOLDER} disabled className="text-[#61758D]">
                  {placeholderText}
                </option>
                {loading ? (
                  <option value={PLACEHOLDER} disabled>
                    Loading...
                  </option>
                ) : !options ? (
                  <option value={PLACEHOLDER} disabled>
                    No options available
                  </option>
                ) : (
                  options?.map((option) => (
                    <option
                      key={option.id}
                      value={option.value}
                      className="text-[#61758D] bg-white"
                    >
                      {option.name}
                    </option>
                  ))
                )}
              </CatalystSelect>
              <div className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <ChevronDownIcon
                  className="h-4 w-4 text-[#61758D]"
                  aria-hidden="true"
                />
              </div>
            </div>
            {name in errors && (
              <ErrorMessage>{`${
                errors[name].message || `${name} is required`
              }`}</ErrorMessage>
            )}
          </Field>
        </>
      )}
    />
  )
}

export default Select
