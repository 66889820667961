import { createSlice, PayloadAction, createAction } from '@reduxjs/toolkit'
import { produce } from 'immer'
import { Feature as GeoJsonFeature, FeatureCollection } from 'geojson'
import {
  MapState,
  ViewState,
  CountyState,
  FlyoutConfig,
  DrawModeConfig,
  SerializableFeature,
} from './mapTypes'
import { Feature, RenderConfig } from 'types/graphql'
import {
  LayerGroup,
  Layer,
  LayerType,
  LayerActivePayload,
} from '../LayerSelector/LayerTypes'
import {
  updateLayerTree,
  updateOrgLayerTree,
  pruneLayerTree,
} from 'src/lib/layerUtils'
import { baseLayers } from '../LayerSelector/layersConfig'
import { PublicLayer } from 'types/graphql'
import { CoordinateType } from 'src/lib/coordinateUtils'

export const initialState: MapState = {
  drawMode: {
    mode: 'simple_select',
    updatedBy: 'draw-control',
  },
  zoomThreshold: 12,
  viewState: {
    bearing: 0,
    longitude: -122.4443,
    latitude: 47.4029,
    pitch: 0,
    zoom: 10,
  },
  currentCounties: [] as CountyState[],
  baseLayers: baseLayers,
  drawingData: null,
  measurementLabels: null,
  flyoutConfig: {
    component: '',
    featureType: null,
    parentComponent: null,
  },
  layerData: {
    public: [] as Array<Layer | LayerGroup>,
    organization: [] as Array<Layer | LayerGroup>,
  },
  featureToUpdate: null,
  lastCreatedFeature: null,
  lastEditedFeature: null,
  lastDeletedFeature: null,
  tileCacheBuster: Date.now(),
  downloadBoundingBox: null,
  displayScaleBar: true,
  scaleUnit: 'imperial' as 'imperial' | 'metric',
  coordinateSystem: 'SPCS' as 'SPCS' | 'DD' | 'DMS',
  hideCoordinates: false,
  initialPointNumber: 1,
  selectedResolution: 'low' as 'low' | 'medium' | 'high',
  isCreatingOfflineMap: false,
  offlineMapUI: {
    isCreating: false,
    currentView: 'list',
  },
}

export const setScaleUnit = createAction<'imperial' | 'metric'>(
  'map/setScaleUnit'
)

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    setViewState: (state, action: PayloadAction<ViewState>) => {
      state.viewState = action.payload
    },
    setFeatureToUpdate: (state, action: PayloadAction<SerializableFeature>) => {
      state.featureToUpdate = action.payload
    },
    setCurrentCounties: (state, action: PayloadAction<CountyState[]>) => {
      state.currentCounties = [...action.payload]
    },
    selectBaseLayer: (state, action: PayloadAction<Layer['id']>) => {
      state.baseLayers = [...state.baseLayers].map((layer) => {
        if (layer.id === action.payload) {
          return {
            ...layer,
            active: true,
          }
        } else {
          return {
            ...layer,
            active: false,
          }
        }
      })
    },
    setDrawingData: (state, action: PayloadAction<FeatureCollection>) => {
      state.drawingData = action.payload
    },
    setMeasurementLabels: (state, action: PayloadAction<FeatureCollection>) => {
      state.measurementLabels = action.payload
    },
    setDrawMode: (state, action: PayloadAction<DrawModeConfig>) => {
      state.drawMode = action.payload
    },
    setLastCreatedFeature: (state, action: PayloadAction<Feature>) => {
      state.lastCreatedFeature = action.payload
    },
    setLastEditedFeature: (state, action: PayloadAction<Feature>) => {
      state.lastEditedFeature = action.payload
    },
    setLastDeletedFeature: (state, action: PayloadAction<Feature>) => {
      state.lastDeletedFeature = action.payload
    },
    setFlyoutConfig: (state, action: PayloadAction<FlyoutConfig>) => {
      state.flyoutConfig = action.payload
    },
    setLayerVisibility: (state, action: PayloadAction<LayerActivePayload>) => {
      state.layerData[action.payload.section] = produce(
        state.layerData[action.payload.section],
        (draft) => {
          const { layerId, desiredState } = action.payload
          const updateLayer = (layers: Array<Layer | LayerGroup>) => {
            for (const layer of layers) {
              if (layer.id === layerId) {
                layer.active = desiredState
                return
              }
              if (layer.type === LayerType.layerGroup) {
                const layerGroup = layer as LayerGroup
                updateLayer(layerGroup.children)
              }
            }
          }
          updateLayer(draft)
        }
      )
    },
    setPublicLayerData: (state, action: PayloadAction<PublicLayer[]>) => {
      state.layerData.public = pruneLayerTree(
        updateLayerTree(state.layerData.public, action.payload),
        action.payload.map((layer) => layer.layerId)
      )
    },
    setOrganizationLayerData: (
      state,
      action: PayloadAction<RenderConfig[]>
    ) => {
      state.layerData.organization = pruneLayerTree(
        updateOrgLayerTree(state.layerData.organization, action.payload),
        action.payload.map((config) => config.id)
      )
    },
    setTileCacheBuster: (state) => {
      state.tileCacheBuster = Date.now()
    },
    setDownloadBoundingBox: (
      state,
      action: PayloadAction<GeoJsonFeature | null>
    ) => {
      state.downloadBoundingBox = action.payload
    },
    setDisplayScaleBar: (state, action: PayloadAction<boolean>) => {
      state.displayScaleBar = action.payload
    },
    setHideCoordinates: (state, action: PayloadAction<boolean>) => {
      state.hideCoordinates = action.payload
    },
    setCoordinateSystem: (state, action: PayloadAction<CoordinateType>) => {
      state.coordinateSystem = action.payload
    },
    setInitialPointNumber: (state, action: PayloadAction<number>) => {
      state.initialPointNumber = action.payload
    },
    setSelectedResolution: (
      state,
      action: PayloadAction<'low' | 'medium' | 'high'>
    ) => {
      state.selectedResolution = action.payload
    },
    setIsCreatingOfflineMap: (state, action: PayloadAction<boolean>) => {
      state.isCreatingOfflineMap = action.payload
    },
    setOfflineMapUI: (
      state,
      action: PayloadAction<{
        isCreating: boolean
        currentView: 'list' | 'create'
        editMode?: boolean
        editData?: {
          id: number
          name: string
          bounds: [number, number, number, number]
          resolution: 'low' | 'medium' | 'high'
          description?: string
        }
      }>
    ) => {
      state.offlineMapUI = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setScaleUnit, (state, action) => {
      state.scaleUnit = action.payload
    })
  },
})

export const {
  setViewState,
  setCurrentCounties,
  selectBaseLayer,
  setFlyoutConfig,
  setDrawingData,
  setMeasurementLabels,
  setDrawMode,
  setLastCreatedFeature,
  setLastEditedFeature,
  setLastDeletedFeature,
  setLayerVisibility,
  setFeatureToUpdate,
  setPublicLayerData,
  setOrganizationLayerData,
  setTileCacheBuster,
  setDownloadBoundingBox,
  setDisplayScaleBar,
  setHideCoordinates,
  setCoordinateSystem,
  setInitialPointNumber,
  setSelectedResolution,
  setIsCreatingOfflineMap,
  setOfflineMapUI,
} = mapSlice.actions

export default mapSlice.reducer
