import {
  LayerIcon,
  LayerManagerIcon,
  CircleStackIcon,
  UploadIcon,
  DownloadIcon,
  OfflineMapsIcon,
} from 'src/Icons'
export const navigationItems = [
  { name: 'Layers', icon: LayerIcon },
  { name: 'LayerManager', icon: LayerManagerIcon },
  { name: 'CircleStack', icon: CircleStackIcon },
  { name: 'FileUpload', icon: UploadIcon },
  { name: 'FileDownload', icon: DownloadIcon },
  { name: 'OfflineMaps', icon: OfflineMapsIcon },
]
