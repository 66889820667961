import { FeatureType, ApiFeatureType, FeatureOption } from 'src/types/features'
import { GeoJsonTypes, FeatureCollection, Feature, Geometry } from 'geojson'

export const featureOptions: FeatureOption[] = [
  {
    id: 1,
    value: 'point_geom',
    name: 'Point',
  },
  {
    id: 2,
    value: 'line_geom',
    name: 'Line',
  },
  {
    id: 3,
    value: 'polygon_geom',
    name: 'Polygon',
  },
]

export const getFeatureTypeLabel = (type: FeatureType): string => {
  switch (type) {
    case FeatureType.circle:
      return 'Point'
    case FeatureType.symbol:
      return 'Point'
    case FeatureType.line:
      return 'Line'
    case FeatureType.fill:
      return 'Polygon'
    default:
      return 'Unsupported Type'
  }
}

const fromApiTypeMap: { [key in ApiFeatureType]: FeatureType } = {
  point_geom: FeatureType.symbol, // points are currently treated as symbols
  line_geom: FeatureType.line,
  polygon_geom: FeatureType.fill,
  // Remaining types are not currently supported
  FillExtrusion: FeatureType['fill-extrusion'],
  Heatmap: FeatureType.heatmap,
  Hillshade: FeatureType.hillshade,
  Raster: FeatureType.raster,
  Symbol: FeatureType.symbol,
  Background: FeatureType.background,
  Unsupported: FeatureType.unsupported,
}

const toApiTypeMap: { [key in FeatureType]: ApiFeatureType } = {
  [FeatureType.circle]: 'point_geom', // points are currently treated as symbols in the map
  [FeatureType.symbol]: 'point_geom',
  [FeatureType.line]: 'line_geom',
  [FeatureType.fill]: 'polygon_geom',
  // Remaining types are not currently supported
  [FeatureType['fill-extrusion']]: 'FillExtrusion',
  [FeatureType.heatmap]: 'Heatmap',
  [FeatureType.hillshade]: 'Hillshade',
  [FeatureType.raster]: 'Raster',
  [FeatureType.background]: 'Background',
  [FeatureType.unsupported]: 'Unsupported',
}

export const fromApiFeatureType = (type: string): FeatureType =>
  fromApiTypeMap[type]

export const toApiFeatureType = (type: FeatureType): ApiFeatureType =>
  toApiTypeMap[type]

const fromGeoJsonTypeMap: { [key in GeoJsonTypes]: FeatureType } = {
  Point: FeatureType.symbol,
  LineString: FeatureType.line,
  Polygon: FeatureType.fill,
  MultiLineString: FeatureType.unsupported,
  MultiPolygon: FeatureType.unsupported,
  MultiPoint: FeatureType.unsupported,
  GeometryCollection: FeatureType.unsupported,
  Feature: FeatureType.unsupported,
  FeatureCollection: FeatureType.unsupported,
}

export const fromGeoJsonTypes = (type: GeoJsonTypes): FeatureType => {
  return fromGeoJsonTypeMap[type]
}

/**
 * Retrieves the Feature Type of a FeatureCollection.
 *
 * @param drawingData The FeatureCollection to get the feature type from
 * @returns The Feature Type or null if not found
 */
export function getFeatureType(
  drawingData: FeatureCollection | null | undefined
): FeatureType | null {
  const feature = getFeatureFromCollection(drawingData)
  if (feature && feature.geometry) {
    return fromGeoJsonTypes(feature.geometry.type)
  }
  return null
}

/**
 * Safely retrieves a feature from a FeatureCollection.
 *
 * @param featureCollection The FeatureCollection to get the feature from
 * @param index The index of the feature to retrieve (default: 0)
 * @returns The requested Feature or null if not found
 * @throws Error if the featureCollection is null or undefined
 */
export function getFeatureFromCollection(
  featureCollection: FeatureCollection | null | undefined,
  index = 0
): Feature<Geometry> | null {
  if (!featureCollection) {
    throw new Error('FeatureCollection is null or undefined')
  }

  if (featureCollection.features.length === 0) {
    console.warn('FeatureCollection is empty')
    return null
  }

  if (index < 0 || index >= featureCollection.features.length) {
    console.warn(`Invalid index ${index}. Using index 0 instead.`)
    index = 0
  }

  return featureCollection.features[index] || null
}

// Retrieves the Geometry Type of a GeoJSON Geometry object.
type GeometryType =
  | 'Point'
  | 'LineString'
  | 'Polygon'
  | 'MultiPoint'
  | 'MultiLineString'
  | 'MultiPolygon'
  | 'GeometryCollection'

export function isGeometryType<T extends Geometry>(
  geometry: Geometry,
  type: GeometryType
): geometry is T {
  return geometry.type === type
}
