export const flyoutSizes = {
  Layers: 'small',
  LayerManager: 'large',
  CreateFeature: 'xsmall',
  EditFeature: 'xsmall',
  FileUpload: 'xsmall',
  FileDownload: 'xsmall',
  Loading: 'xsmall',
  Profile: 'xsmall',
  MapSettings: 'xsmall',
  Team: 'xsmall',
  Billing: 'xsmall',
  Help: 'xsmall',
  OfflineMaps: 'xsmall',
  CreateOfflineMap: 'xsmall',
  LayerInfo: 'xsmall',
}
