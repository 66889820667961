import {
  Select as HeadlessSelect,
  type SelectProps as HeadlessSelectProps,
} from '@headlessui/react'
import { clsx } from 'clsx'
import { forwardRef } from 'react'

export const Select = forwardRef<HTMLSelectElement, HeadlessSelectProps>(
  function Select({ className, multiple, ...props }, ref) {
    return (
      <HeadlessSelect
        ref={ref}
        multiple={multiple}
        {...props}
        className={clsx([
          'relative block w-full appearance-none rounded-lg',
          'pl-3 pr-10',
          '[&_optgroup]:font-semibold',
          'text-base/6 text-[#61758d] placeholder:text-[#61758d] sm:text-sm/6',
          'focus:outline-none',
          className,
        ])}
        style={{
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          appearance: 'none',
          backgroundImage: 'none',
          outline: 'none',
          boxShadow: 'none',
          border: 'none',
        }}
      />
    )
  }
)
