import { lazy } from 'react'

export const flyoutComponents = {
  Layers: lazy(() => import('src/components/LayerSelector/LayerSelector')),
  LayerManager: lazy(() => import('src/components/LayerManager/LayerManager')),
  CreateFeature: lazy(
    () => import('src/components/Feature/CreateFeature/CreateFeature')
  ),
  EditFeature: lazy(
    () => import('src/components/Feature/EditFeature/EditFeature')
  ),
  FileUpload: lazy(
    () => import('src/components/FileUpload/FileUploadComponent')
  ),
  FileDownload: lazy(
    () => import('src/components/FileDownload/FileDownloadComponent')
  ),
  Profile: lazy(() => import('src/components/Profile/Profile')),
  MapSettings: lazy(() => import('src/components/MapSettings/MapSettings')),
  Team: lazy(() => import('src/components/Team/Team')),
  Billing: lazy(() => import('src/components/Billing/Billing')),
  Help: lazy(() => import('src/components/Help/Help')),
  OfflineMaps: lazy(() => import('src/components/OfflineMaps/OfflineMaps')),
  CreateOfflineMap: lazy(
    () => import('src/components/OfflineMaps/OfflineMaps')
  ),
  LayerInfo: lazy(() => import('src/components/LayerInfo/LayerInfoContainer')),
}
