import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { setFlyoutConfig } from 'src/components/Map/mapSlice'

export const useFlyoutConfig = () => {
  const dispatch = useDispatch()
  const flyoutConfig = useSelector((state: RootState) => state.map.flyoutConfig)

  const updateFlyoutConfig = (config) => {
    dispatch(
      setFlyoutConfig({
        ...config,
        timestamp: Date.now(),
      })
    )
  }

  return { flyoutConfig, updateFlyoutConfig }
}
