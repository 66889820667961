import { useQuery } from '@redwoodjs/web'
import { useAuth } from 'src/auth'
import { RENDER_CONFIGS_BY_ORG_QUERY } from 'src/queries/renderConfigs'
import { FeatureType } from 'src/types/features'
import RenderConfigDisclosure from 'src/components/RenderConfigDisclosure/RenderConfigDisclosure'
import { Heading, Subheading } from 'src/components/catalyst/heading'
import { Text } from 'src/components/catalyst/text'

export default function UserSettingsSection() {
  const { currentUser } = useAuth()

  const { data: renderConfigsData, error: renderConfigLoadingError } = useQuery(
    RENDER_CONFIGS_BY_ORG_QUERY,
    {
      variables: { input: { organizationId: currentUser?.organizationId } },
      skip: !currentUser?.organizationId,
    }
  )

  const renderConfigs = renderConfigsData
    ? {
        [FeatureType.circle]: renderConfigsData?.renderConfigs.filter(
          (config) => {
            return config.featureType === FeatureType.circle
          }
        ),
        [FeatureType.line]: renderConfigsData?.renderConfigs.filter(
          (config) => {
            return config.featureType === FeatureType.line
          }
        ),
        [FeatureType.fill]: renderConfigsData?.renderConfigs.filter(
          (config) => {
            return config.featureType === FeatureType.fill
          }
        ),
      }
    : undefined

  return (
    <>
      <div className="mx-auto w-full space-y-4 sm:space-y-8 lg:mx-0 lg:max-w-none">
        <section>
          <Heading level={1} className=" font-semibold leading-7 ">
            User Settings
          </Heading>
          <Text className="mt-1 text-sm leading-6 ">
            Customize your Silo experience
          </Text>
        </section>
        <section className="mt-6">
          <div>
            <Subheading
              level={2}
              className="text-md font-semibold leading-7 text-heading"
            >
              Custom Geometries
            </Subheading>
            <Text className="mt-1 text-sm leading-6 text-gray-600">
              User-defined points, lines, and polygons for your organization
            </Text>
          </div>
          {renderConfigLoadingError ? (
            <div className="mt-4 text-sm text-red-600">
              {`${renderConfigLoadingError}`}
            </div>
          ) : (
            <div className="mt-4">
              {renderConfigs &&
                Object.keys(renderConfigs).map((key) => {
                  const featureType = key as FeatureType
                  return (
                    <div key={featureType} className="my-2">
                      {/* TODO: renderConfigDisclosure needs to be updated to only allow for colors in ColorPickerModal according to have database values align with Figma design */}
                      <RenderConfigDisclosure
                        open={false}
                        renderConfigs={renderConfigs[featureType]}
                        featureType={featureType}
                      />
                    </div>
                  )
                })}
            </div>
          )}
        </section>
      </div>
    </>
  )
}
