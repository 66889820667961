import { type AnyPaint, type AnyLayout } from 'mapbox-gl'

export enum LayerScope {
  BASE = 'BASE',
  FEDERAL = 'FEDERAL',
  STATE = 'STATE',
  COUNTY = 'COUNTY',
  CITY = 'CITY',
  USER = 'USER',
}

export type FeatureType =
  | 'background'
  | 'circle'
  | 'fill'
  | 'fill-extrusion'
  | 'heatmap'
  | 'hillshade'
  | 'line'
  | 'raster'
  | 'symbol'

export enum ServiceType {
  ARCGIS = 'ARCGIS',
  SILO = 'SILO',
  MAPBOX = 'MAPBOX',
}

export type Source = {
  id: number
  service: ServiceType
  displayName: string
  url?: string
  homepageUrl?: string
}

export type Annotation = {
  id: string
  layout: object
  paint: object
  source: string
  type: string
}

export type ContourProperties = {
  elevation: {
    id: string
    units: string
  }
}

export type LayerProperties = {
  properties: ContourProperties
}
export type RenderOptions = {
  featureType: FeatureType
  paint: AnyPaint
  layout: AnyLayout
}

export type LayerDataSection = 'public' | 'organization'

export type LayerActivePayload = {
  layerId: string
  desiredState: boolean
  section: LayerDataSection
}

export type LayerData = {
  public: Array<Layer | LayerGroup>
  organization: Array<Layer | LayerGroup>
}

export type LayerMetadata = {
  section: LayerDataSection
  featureType: FeatureType
}

export type Layer = {
  type: LayerType
  id: number
  active: boolean
  displayName: string
  scope: LayerScope
  source: Source
  featureType?: FeatureType
  annotations?: Annotation[]
  renderOptions?: RenderOptions
  properties?: LayerProperties
  serviceType?: ServiceType
}

export type LayerGroup = {
  type: LayerType.layerGroup
  id: string
  path: PathPivot[]
  active: boolean
  displayName: string
  url: string
  children: Array<Layer | LayerGroup>
}

export enum LayerType {
  layer = 'layer',
  layerGroup = 'layerGroup',
}

export enum PathPivot {
  BUILDINGS = 'BUILDINGS',
  CONTROL = 'CONTROL',
  CRITICAL_HABITAT = 'CRITICAL_HABITAT',
  EASEMENTS = 'EASEMENTS',
  ELEVATION_CONTOURS = 'ELEVATION_CONTOURS',
  ENVIRONMENTAL = 'ENVIRONMENTAL',
  EROSION_HAZARD = 'EROSION_HAZARD',
  FIBER_TELECOM = 'FIBER_TELECOM',
  FLOOD = 'FLOOD',
  GAS = 'GAS',
  HYDRO = 'HYDRO',
  LANDSLIDE_HAZARD = 'LANDSLIDE_HAZARD',
  PARCELS = 'PARCELS',
  PLSS = 'PLSS',
  POWER = 'POWER',
  RIVERS_STREAMS = 'RIVERS_STREAMS',
  ROADS = 'ROADS',
  SEWER = 'SEWER',
  SOILS = 'SOILS',
  STEEP_SLOPES = 'STEEP_SLOPES',
  STORM = 'STORM',
  SURVEYS = 'SURVEYS',
  TOPOGRAPHY = 'TOPOGRAPHY',
  WATER = 'WATER',
  WETLAND = 'WETLAND',
  ZONING = 'ZONING',
  SURVEY = 'SURVEY',
  ARCHITECTURAL = 'ARCHITECTURAL',
  GEOTECH = 'GEOTECH',
  CIVIL = 'CIVIL',
  PLANNING = 'PLANNING',
  POINT = 'point',
  LINE = 'line',
  POLYGON = 'polygon',
}
