import React, { Suspense } from 'react'
import { useDispatch } from 'react-redux'
import { navigate, routes } from '@redwoodjs/router'

import Flyout from 'src/components/Flyout/Flyout'
import Navigation from 'src/components/Navigation/Navigation'
import { useFlyoutConfig } from 'src/hooks/useFlyoutConfig'
import { setDrawMode } from 'src/components/Map/mapSlice'
import { LoadingSpinner } from 'src/pages/SettingsPage/LoadingSpinner'
import { flyoutComponents } from './FlyoutComponents'
import { flyoutSizes } from './FlyoutConfigs'

type MapLayoutProps = {
  children?: React.ReactNode
}

const MapLayout = ({ children }: MapLayoutProps) => {
  const dispatch = useDispatch()
  const { flyoutConfig, updateFlyoutConfig } = useFlyoutConfig()
  const { component, featureType } = flyoutConfig

  const FlyoutComponent = component ? flyoutComponents[component] : null

  const closeFlyout = () => updateFlyoutConfig({ component: '' })

  const flyoutSize = flyoutSizes[component]

  const handleItemClick = (itemName: string) => {
    if (itemName === 'Settings') {
      navigate(routes.settings())
      return
    }

    if (component === itemName) {
      closeFlyout()
    } else {
      updateFlyoutConfig({ component: itemName, featureType })
      dispatch(
        setDrawMode({ mode: 'simple_select', updatedBy: 'flyout-manager' })
      )
    }
  }

  return (
    <main className="relative h-[100vh]">
      {children}
      <Navigation currentComponent={component} onItemClick={handleItemClick} />
      {FlyoutComponent && (
        <Suspense
          fallback={
            <Flyout size={flyoutSize} isLoading={true}>
              <div className="flex h-full w-full items-center justify-center">
                <LoadingSpinner />
              </div>
            </Flyout>
          }
        >
          <Flyout size={flyoutSize} isLoading={false}>
            <FlyoutComponent onClose={closeFlyout} />
          </Flyout>
        </Suspense>
      )}
    </main>
  )
}

export default MapLayout
