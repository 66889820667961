import { CountyState } from 'src/components/Map/mapTypes'
import { PublicLayer, RenderConfig } from 'types/graphql'
import { type AnyPaint, type AnyLayout } from 'mapbox-gl'
import {
  Layer,
  LayerGroup,
  LayerType,
  LayerScope,
  FeatureType,
  PathPivot,
  ServiceType,
  Annotation,
  LayerProperties,
} from 'src/components/LayerSelector/LayerTypes'

enum CountyAction {
  LEAVING = 'leaving',
  ENTERING = 'entering',
}

type DisjointUnionCountyItem = {
  value: CountyState
  action: CountyAction
}

export function disjointUnionWithAction(
  arr1: CountyState[],
  arr2: CountyState[]
): DisjointUnionCountyItem[] {
  const leavingItems = arr1.filter(
    (item) => !arr2.filter((item2) => item.county === item2.county).length
  )
  const enteringItems = arr2.filter(
    (item) => !arr1.filter((item1) => item.county === item1.county).length
  )

  const result: DisjointUnionCountyItem[] = [
    ...leavingItems.map((item) => ({
      value: item,
      action: CountyAction.LEAVING,
    })),
    ...enteringItems.map((item) => ({
      value: item,
      action: CountyAction.ENTERING,
    })),
  ]

  return result
}

export function accumulateActiveLayers(
  layerData: Array<Layer | LayerGroup>,
  activeLayers: Array<Layer | LayerGroup> = []
): Layer[] {
  return layerData.reduce(
    (acc: Array<Layer | LayerGroup>, item: Layer | LayerGroup) => {
      if (item.type === LayerType.layerGroup) {
        const coercedGroup = item as LayerGroup
        return [
          ...acc,
          ...accumulateActiveLayers(coercedGroup.children, activeLayers),
        ]
      } else if (item.active) {
        return [...acc, item]
      } else {
        return acc
      }
    },
    activeLayers
  ) as Layer[]
}

export function toCapitalCase(text: string, separator: string): string {
  return text
    .split(separator)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export function getSourceName(layer: PublicLayer): string {
  const names = {
    [LayerScope.BASE]: 'Predefined Base Layers',
    [LayerScope.FEDERAL]: 'Federal Data',
    [LayerScope.STATE]: layer.state,
    [LayerScope.COUNTY]: layer.county,
    [LayerScope.CITY]: layer.city,
  }
  return names[layer.scope] || 'Unknown'
}

export function sortLayers(layers: Array<Layer | LayerGroup>) {
  return layers.sort((a, b) => (a.displayName >= b.displayName ? 1 : -1))
}

export const pruneLayerTree = <T>(
  layerTree: Array<Layer | LayerGroup>,
  validIds: Array<T>,
  inclusionFn: (validIds: Array<T>, layer: Layer) => boolean = (
    validIds,
    layer
  ) => validIds.includes(layer.id as T)
): Array<Layer | LayerGroup> => {
  return layerTree.filter((layer) => {
    if (layer.type === LayerType.layer) {
      return inclusionFn(validIds, layer as Layer)
    } else if (layer.type === LayerType.layerGroup) {
      const coercedGroup = layer as LayerGroup
      // Recursively process children of layer groups
      coercedGroup.children = pruneLayerTree(
        coercedGroup.children,
        validIds,
        inclusionFn
      )
      // Remove the group if it has no children left
      return coercedGroup.children.length > 0
    }
    return true
  })
}

export function updateOrgLayerTree(
  layerTree: Array<Layer | LayerGroup>,
  renderConfigs: RenderConfig[]
): Array<Layer | LayerGroup> {
  const updatedLayerTree = [...layerTree]

  for (const config of renderConfigs) {
    const { id: configId, name, featureTags, featureType } = config

    // Handle multiple feature tags
    for (const featureTag of featureTags) {
      let currentLayerTree = updatedLayerTree
      let depth = 0
      const currentPath: PathPivot[] = []

      // Build the pathPivots array
      const pathPivots: PathPivot[] = [featureTag.type as PathPivot]

      for (const pathPivot of pathPivots) {
        currentPath.push(pathPivot)
        depth++

        let layerGroup = currentLayerTree.find((group) => {
          if (group.type === LayerType.layerGroup) {
            const coercedGroup = group as LayerGroup
            return (
              coercedGroup.path.slice(0, depth).join(',') ===
              currentPath.join(',')
            )
          }
          return false
        }) as LayerGroup

        if (!layerGroup) {
          layerGroup = {
            type: LayerType.layerGroup,
            id: currentPath.join('-'),
            path: [...currentPath],
            active: false,
            displayName: toCapitalCase(pathPivot, '_'),
            url: '',
            children: [],
          }
          currentLayerTree.push(layerGroup as LayerGroup)
          sortLayers(currentLayerTree)
        }
        currentLayerTree = layerGroup.children as Array<Layer | LayerGroup>
      }

      // Add the RenderConfig as a leaf node
      if (
        !currentLayerTree.find((layer) => layer.id === JSON.stringify(configId))
      ) {
        currentLayerTree.push({
          type: LayerType.layer,
          id: configId,
          active: false,
          displayName: name,
          scope: LayerScope.USER,
          featureType: featureType,
          source: {
            id: configId,
            service: ServiceType.SILO,
            displayName: name,
          },
        })
      }
      sortLayers(currentLayerTree)
    }
  }
  return updatedLayerTree
}

export function updateLayerTree(
  layerTree: Array<Layer | LayerGroup>,
  publicLayers: PublicLayer[]
): Array<Layer | LayerGroup> {
  const updatedLayerTree = [...layerTree]

  for (const publicLayer of publicLayers) {
    const {
      path,
      layerId,
      sourceId,
      layerUrl,
      homepageUrl,
      displayName,
      scope,
      featureType,
      serviceType,
      paint,
      layout,
      annotations,
      properties,
    } = publicLayer
    let currentLayerTree = updatedLayerTree
    let depth = 0
    const currentPath: PathPivot[] = []

    for (const pathPivot of path as PathPivot[]) {
      currentPath.push(pathPivot)
      depth++

      let layerGroup = currentLayerTree.find((group) => {
        if (group.type === LayerType.layerGroup) {
          const coercedGroup = group as LayerGroup
          return (
            coercedGroup.path.slice(0, depth).join(',') ===
            currentPath.join(',')
          )
        }
        return false
      }) as LayerGroup

      if (!layerGroup) {
        layerGroup = {
          type: LayerType.layerGroup,
          id: currentPath.join('-'),
          path: currentPath,
          active: false,
          displayName: toCapitalCase(pathPivot, '_'),
          url: '',
          children: [],
        }
        currentLayerTree.push(layerGroup as LayerGroup)
        sortLayers(currentLayerTree)
      }
      currentLayerTree = layerGroup.children as LayerGroup[]
    }

    if (!currentLayerTree.find((layer) => layer.id === layerId)) {
      currentLayerTree.push({
        type: LayerType.layer,
        id: layerId,
        active: false,
        annotations: annotations as Annotation[],
        displayName,
        properties: properties as LayerProperties,
        scope: scope as LayerScope,
        source: {
          id: sourceId,
          service: serviceType as ServiceType,
          displayName: toCapitalCase(getSourceName(publicLayer), ' '),
          url: layerUrl,
          homepageUrl: homepageUrl,
        },
        renderOptions: {
          featureType: featureType as FeatureType,
          paint: paint as AnyPaint,
          layout: layout as AnyLayout,
        },
      })
    }

    sortLayers(currentLayerTree)
  }
  return updatedLayerTree
}
