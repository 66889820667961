import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/store'
import { setOnlineStatus } from './onlineStatusSlice'

export default function OnlineStatusIndicator() {
  const dispatch = useDispatch()
  const isOnline = useSelector(
    (state: RootState) => state.onlineStatus.isOnline
  )

  useEffect(() => {
    const handleOnline = () => dispatch(setOnlineStatus(true))
    const handleOffline = () => dispatch(setOnlineStatus(false))

    window.addEventListener('online', handleOnline)
    window.addEventListener('offline', handleOffline)

    return () => {
      window.removeEventListener('online', handleOnline)
      window.removeEventListener('offline', handleOffline)
    }
  }, [dispatch])

  return (
    <div
      className={`absolute bottom-0 right-0 w-3 h-3 rounded-full border-[0.5px] border-white ${
        isOnline ? 'bg-green-500' : 'bg-gray-500'
      }`}
    />
  )
}
