export default function LayerManagerIcon() {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_101_1375)">
        <rect width="50" height="50" rx="8" fill="none" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M35.6695 23.4037C35.8753 23.5323 36.0002 23.7579 36 24.0006C35.9998 24.2433 35.8744 24.4687 35.6684 24.5969L33.8129 25.7518L33.5 24.5H33.163L33.9683 23.9988L31.9141 22.7149L24.3716 27.4095C24.1441 27.5511 23.8559 27.551 23.6285 27.4095L16.086 22.7149L14.0318 23.9988L24 30.203L25.6661 29.1661L26.687 30.187L24.3715 31.6282C24.1441 31.7698 23.8558 31.7698 23.6285 31.6282L16.0859 26.9337L14.0286 28.2195L24 34.4671L25.8242 33.3242L26.5 34L26.7634 34.3951L24.3733 35.8927C24.1449 36.0357 23.8551 36.0357 23.6267 35.8927L12.3298 28.8145C12.1245 28.6859 11.9999 28.4606 12 28.2183C12.0001 27.976 12.125 27.7509 12.3305 27.6225L14.7565 26.1062L12.3316 24.5969C12.1256 24.4687 12.0002 24.2432 12 24.0006C11.9998 23.758 12.1247 23.5323 12.3305 23.4037L14.7565 21.8874L12.3316 20.3782C12.1257 20.25 12.0004 20.0248 12 19.7823C11.9996 19.5398 12.1243 19.3142 12.3298 19.1854L23.6267 12.1073C23.855 11.9642 24.145 11.9642 24.3733 12.1073L35.6702 19.1854C35.8757 19.3142 36.0004 19.5398 36 19.7823C35.9996 20.0248 35.8743 20.25 35.6684 20.3782L33.2435 21.8874L35.6695 23.4037ZM24 13.5329L14.0306 19.7792L24 25.9843L33.9694 19.7792L24 13.5329Z"
          fill="currentColor"
        />
        <mask
          id="mask0_101_1375"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="25"
          y="24"
          width="14"
          height="14"
        >
          <path d="M25 24H39V38H25V24Z" fill="white" />
        </mask>
        <g mask="url(#mask0_101_1375)">
          <path
            d="M31.9996 28.8489C30.8116 28.8489 29.8485 29.812 29.8485 31C29.8485 32.188 30.8116 33.151 31.9996 33.151C33.1875 33.151 34.1506 32.188 34.1506 31C34.1506 29.812 33.1875 28.8489 31.9996 28.8489ZM38.0371 29.465L37.4547 29.9444C36.7904 30.4914 36.7904 31.5086 37.4547 32.0555L38.0371 32.535C38.2435 32.7049 38.2968 32.9991 38.1632 33.2306L37.0132 35.2225C36.8795 35.454 36.598 35.5549 36.3477 35.4612L35.6413 35.1965C34.8355 34.8947 33.9545 35.4033 33.813 36.2521L33.689 36.9962C33.6451 37.2598 33.4169 37.4531 33.1496 37.4531H30.8495C30.5822 37.4531 30.3541 37.2598 30.3101 36.9962L30.1861 36.2521C30.0447 35.4033 29.1637 34.8947 28.3579 35.1965L27.6515 35.4612C27.4011 35.5549 27.1197 35.454 26.986 35.2225L25.836 33.2306C25.7023 32.9991 25.7556 32.7049 25.962 32.535L26.5444 32.0555C27.2088 31.5086 27.2088 30.4914 26.5444 29.9444L25.962 29.465C25.7556 29.2951 25.7023 29.0009 25.836 28.7694L26.986 26.7775C27.1197 26.546 27.4011 26.445 27.6515 26.5388L28.3579 26.8034C29.1637 27.1053 30.0447 26.5967 30.1861 25.7479L30.3101 25.0038C30.3541 24.7401 30.5822 24.5469 30.8495 24.5469H33.1496C33.4169 24.5469 33.6451 24.7401 33.689 25.0038L33.813 25.7479C33.9545 26.5967 34.8355 27.1053 35.6413 26.8034L36.3477 26.5388C36.598 26.445 36.8795 26.546 37.0132 26.7775L38.1632 28.7694C38.2968 29.0009 38.2435 29.2951 38.0371 29.465Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_101_1375">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
