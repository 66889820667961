import { combineReducers, configureStore } from '@reduxjs/toolkit'
import mapReducer from './components/Map/mapSlice'
import joyrideReducer from './components/Map/joyrideSlice'
import onlineStatusReducer from './components/OnlineStatusIndicator/onlineStatusSlice'
const rootReducer = combineReducers({
  map: mapReducer,
  joyride: joyrideReducer,
  onlineStatus: onlineStatusReducer,
})

export type RootState = ReturnType<typeof rootReducer>

const store = configureStore({
  reducer: rootReducer,
  // other store configurations like middleware, devTools, etc.
})

export default store
