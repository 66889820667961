import { createSlice } from '@reduxjs/toolkit'

interface OnlineStatusState {
  isOnline: boolean
}

const initialState: OnlineStatusState = {
  isOnline: navigator.onLine,
}

const onlineStatusSlice = createSlice({
  name: 'onlineStatus',
  initialState,
  reducers: {
    setOnlineStatus: (state, action: { payload: boolean }) => {
      state.isOnline = action.payload
    },
  },
})

export const { setOnlineStatus } = onlineStatusSlice.actions
export default onlineStatusSlice.reducer
