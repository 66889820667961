// export { default as DownloadIcon } from './DownloadIcon'
// export { default as LayerIcon } from './LayerIcon'
export { default as LayerManagerIcon } from './LayerManagerIcon'
export { default as LineIcon } from './LineIcon'
export { default as PointIcon } from './PointIcon'
export { default as PolygonIcon } from './PolygonIcon'
// export { default as SearchIcon } from './SearchIcon'
// export { default as SettingsIcon } from './SettingsIcon'
// export { default as UploadIcon } from './UploadIcon'
// export { default as OfflineMapsIcon } from './OfflineMapsIcon'
// export { default as CircleStackIcon } from './CircleStackIcon'

import {
  Square3Stack3DIcon,
  CircleStackIcon as HeroCircleStack,
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  GlobeAltIcon,
  Cog6ToothIcon,
} from '@heroicons/react/24/outline'

export const LayerIcon = () => {
  return <Square3Stack3DIcon className="size-5" />
}

export const CircleStackIcon = () => {
  return <HeroCircleStack className="size-5" />
}

export const DownloadIcon = () => {
  return <ArrowDownTrayIcon className="size-5" />
}

export const UploadIcon = () => {
  return <ArrowUpTrayIcon className="size-5" />
}

export const OfflineMapsIcon = () => {
  return <GlobeAltIcon className="size-5" />
}

export const SettingsIcon = () => {
  return <Cog6ToothIcon className="size-5" />
}
